<template>
  <div class="edit-banner_campaign">
    <b-card>
      <div class="heading">
        <p>
          Edit Campaign
        </p>
      </div>
      <!-- form -->
      <validation-observer ref="editCampaignForm">
        <b-form
          class="mt-2"
          style="
          width: 100%"
          @submit.prevent
        >
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              text-bolder
              label="Campaign Name"
              label-for="blog-edit-title"
              class="mb-2 campaign-label"
            >
              <validation-provider
                v-slot="{ errors }"
                name="campaign name"
                rules="required"
              >
                <b-skeleton
                  v-if="isCampaign"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="blog-edit-title"
                  v-model="blogData.name"
                  placeholder="Please enter Campaign name here"
                  style="height: 42px"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- devices select-->
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              label="Screens"
              label-for="blog-edit-category"
              class="mb-2 campaign-label"
            >
              <b-skeleton
                v-if="isCampaign"
                type="input"
              />
              <v-select
                v-else
                v-model="userSelectedDevices"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                :options="filteredDevices"
                class="search"
                @input="selectDevicesFunc(userSelectedDevices)"
                @search="onSearch"
              />
            </b-form-group>
          </b-col>
          <b-col />
          <b-col
            cols="3"
            class=" text-align-center mx-auto"
          >
            <b-link
              class="mediaText"
            >
              Media
            </b-link>
          </b-col>

        </b-form>
      </validation-observer>
      <!--/ form -->
      <BannerAdsMedia
        :max-time-limit="TimeLimitCondition.maxBannerAdsMediaCondition"
        @addMediaToCampaign="addMediaToCampaignFile"
      />
      <AddedMediaToCampaign
        ref="add-media-cam-ref"
        :added-media="addedFilesToCampaign"
        :max-time-limit="TimeLimitCondition.maxBannerAdsMediaCondition"
        @removeMediaFromList="deleteMediaFromCampaign"
      />
      <div class="d-flex justify-content-center mt-0 ">
        <b-button
          variant="outline-primary"
          class="my-1"
          @click="reviewCampaign"
        >
          Review
        </b-button>
        <b-button
          variant="primary"
          class="m-1"
          :disabled="spinner"
          @click="editCampaign"
        >
          <div
            v-if="spinner"
            class="spinner"
          >
            <b-spinner
              small
            />
          </div>
          Save
        </b-button>
        <b-button
          class="choose my-1"
          @click="cancelCampaign"
        >
          Cancel
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BForm, BCol, BFormGroup, BFormInput, BButton, VBModal, BLink,
  BSkeleton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import MediaType from '@/common/enums/mediaTypeEnum'
import BannerAdsMedia from '@/components/common/BannerAdsMedia.vue'
import AddedMediaToCampaign from '@/components/common/AddedMediaToCampaign.vue'
import { getThumbnailOfFile, showErrorMessage, showToast } from '@/common/global/functions'
import TimeLimitCondition from '@/common/config'
import store from '@/store'
import AccountTypes from '@/common/enums/accountTypeEnum'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'

export default {
  components: {
    BCard,
    BForm,
    BCol,
    AddedMediaToCampaign,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BLink,
    BannerAdsMedia,
    BSkeleton,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](MemberPermissions.INTERNAL_NETWORK_ADS)
    const adminRole = store.getters['user/getUserRole']
    if (memberPermission || (adminRole === AccountTypes.ADMIN)) {
      next()
    } else {
      next({
        name: 'dashboard',
      })
    }
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['id'],
  data() {
    return {
      TimeLimitCondition,
      MediaType,
      AccountTypes,
      isCampaign: false,
      spinner: false,
      search: '',
      addedFilesToCampaign: [],
      userSelectedDevices: [],
      userSelectedGroups: [],
      campaign: '',
      media: [],
      allDevices: [],
      allGroups: [],
      blogData: {
        name: '',
        bannerCampaignId: Number(this.$route.params.id),
        banner_has_group: [],
        banner_has_device: [],
        banner_has_media: [],
      },
      // validation
      required,
    }
  },
  computed: {
    filteredDevices() {
      return this.allDevices.filter(device => !this.userSelectedDevices.map(res => res.id).includes(device.id))
    },
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
  },
  async mounted() {
    await this.getAllDevices()
    await this.getBannerCampaign(this.$route.params.id)
  },
  methods: {
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    async editCampaign() {
      this.$refs.editCampaignForm.validate().then(async success => {
        if (success) {
          if (!this.blogData.banner_has_device.length && !this.blogData.banner_has_group.length) {
            showToast('Edit Internal Network Ad', 'Please add devices or devices group to update internal network ad!', 'danger', 4000)
          } else if (!this.blogData.banner_has_media.length) {
            showToast('Edit Internal Network Ad', 'Please add media to update internal network ad!', 'danger', 4000)
          } else {
            this.spinner = true
            await this.editBannerCampaign()
            this.spinner = false
          }
        }
      })
    },
    editBannerCampaign() {
      if (this.userRole === this.AccountTypes.USER) {
        return this.$axios
          .put('banner-campaigns', this.blogData)
          .then(() => {
            showToast('Edit Internal Network Ad', 'Internal Network Ad has been edited successfully!')
            this.$router.push('/banner-ads')
          }).catch(({
            response: {
              data: {
                statusCode, message,
              },
            },
          }) => {
            if (showErrorMessage(statusCode)) {
              showToast('Edit Internal Network Ad', message.toString(), 'danger')
            }
          })
      }
      return this.$axios
        .put('admin/banner-campaign', this.blogData, {
          headers: {
            userHasGroupId: this.$route.params.userId,
          },
        })
        .then(() => {
          showToast('Update Internal Network Ad', 'Internal Network Ad has been edited successfully!')
          this.$router.push(`/users/${this.$route.params.userId}/user-banner-ads`)
        }).catch(({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) => {
          if (showErrorMessage(statusCode)) {
            showToast('Update Internal Network Ad', message.toString(), 'danger')
          }
        })
    },
    async  getAllDevices() {
      if (this.userRole === AccountTypes.USER) {
        await this.$axios.get(`user/device?offset=${0}&limit=${20}&search_query=${this.search}`)
          .then(({
            data: {
              data,
            },
          }) => {
            this.allDevices = data.results
          }).catch(({
            response: {
              data: {
                statusCode, message,
              },
            },
          }) => {
            if (showErrorMessage(statusCode)) {
              showToast('Edit Internal Network Ad', message.toString(), 'danger')
            }
          })
      } else {
        try {
          const { data: { data } } = await this.$axios.get(`admin/device/${this.$route.params.userId}?offset=${0}&limit=${20}&search_query=${this.search}`)
          this.allDevices = data.results
        } catch ({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('Edit Internal Network Ad', message.toString(), 'danger')
          }
        }
      }
    },

    async  getBannerCampaign(id) {
      this.isCampaign = true
      const payload = {
        userHasGroupId: this.$route.params.userId,
        bannerId: id,
      }
      if (this.userRole === this.AccountTypes.USER) {
        await this.$axios
          .get(`banner-campaigns/${id}`)
          .then(({ data }) => {
          // eslint-disable-next-line prefer-destructuring
            this.campaign = data.data
            this.blogData.name = this.campaign.name
            this.updateIdsOfDevicesInPayload(this.campaign)
            this.updateIdsOfDevicesGroupInPayload(this.campaign)
            this.addedFilesToCampaign = this.campaign.banner_campaign_has_media.map(res => res.media)
            this.updateIdsOfMediaInPayload()
            this.isCampaign = false
          }).catch(({
            response: {
              data: {
                statusCode, message,
              },
            },
          }) => {
            if (showErrorMessage(statusCode)) {
              showToast('Edit Internal Network Ad', message.toString(), 'danger')
            }
          })
      } else {
        try {
          const res = await this.$store.dispatch('bannerAds/getSingleBannerAd', { ...payload })
          if (res.data.statusCode === 200) {
            this.campaign = res.data.data
            this.blogData.name = this.campaign.name
            this.updateIdsOfDevicesInPayload(this.campaign)
            this.updateIdsOfDevicesGroupInPayload(this.campaign)
            this.addedFilesToCampaign = this.campaign.banner_campaign_has_media.map(response => response.media)
            this.updateIdsOfMediaInPayload()
            this.isCampaign = false
          }
        } catch ({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('Edit Internal Network Ad', message.toString(), 'danger')
          }
        }
      }
    },
    updateIdsOfDevicesInPayload(campaign) {
      this.userSelectedDevices = campaign.banner_campaigns_has_devices.map(res => res.device)
      this.blogData.banner_has_device = this.userSelectedDevices.map(res => res.id)
    },
    updateIdsOfDevicesGroupInPayload(campaign) {
      this.userSelectedGroups = campaign.banner_campaign_has_group.map(res => res.device_groups)
      this.blogData.banner_has_group = this.userSelectedGroups.map(res => res.id)
    },
    updateIdsOfMediaInPayload() {
      this.blogData.banner_has_media = this.addedFilesToCampaign.map(res => res.id)
    },
    async onSearch(query, loading) {
      this.search = query
      if (this.search.length) {
        loading(true)
        await this.getAllDevices()
        loading(false)
      } else {
        loading(false)
      }
    },
    addMediaToCampaignFile(object) {
      this.addedFilesToCampaign.push(object)
      this.updateIdsOfMediaInPayload()
    },
    selectDevicesFunc(userSelectedDevices) {
      this.blogData.banner_has_device = userSelectedDevices.map(res => res.id)
    },
    selectGroupFunc(userSelectedGroups) {
      this.blogData.banner_has_group = userSelectedGroups.map(res => res.id)
    },
    deleteMediaFromCampaign(index) {
      this.addedFilesToCampaign.splice(index, 1)
      this.updateIdsOfMediaInPayload()
    },
    cancelCampaign() {
      if (this.userRole === this.AccountTypes.ADMIN) {
        this.$router.push(`/users/${this.$route.params.userId}/user-banner-ads`)
      } else {
        this.$router.push('/banner-ads')
      }
    },
    async reviewCampaign() {
      if (this.addedFilesToCampaign) {
        await this.$store.dispatch('lightBox/lightBoxData', this.addedFilesToCampaign)
      }
    },
  },
}
</script>
<style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>
<style lang="scss">
.edit-banner_campaign {
  .search {
    height: 42px !important;
    .vs__search, .vs__search:focus{
      height:36px !important;
    }
    .vs__dropdown-menu {
      max-height: 80px !important;
      overflow-y: auto !important;
    }
    .vs__dropdown-toggle{
      max-height: 70px !important;
      overflow-y: auto !important;
    }
    .vs__search, .vs__search:focus{
      font-size: medium !important;
    }
  }
  .campaign-label{
    font-size: 13px;
    line-height: 18px;
    color: #323232;
    font-weight: 600;
    font-family: "Montserrat";
  }
}
.heading{
  font-size: 20px;
  line-height: 44px;
  color: #1f58b5;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: center;
}
.mediaText{
  font-size: 14px;
  line-height: 22px;
  color: #323232;
  font-weight: 600;
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  margin-top: 2rem;

}
.addDevice{
  width: 171px;
  height: 65px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 25px;
}
.text{
  font-size: 12px;
  color: #5e5873;
  font-weight: 500;
  font-family: "Montserrat";
}
.ml-0{
  font-size: 10px;
  color: #6e6b7b;
  font-weight: 400;
  font-family: "Montserrat";
}
.choose{
  display: flex;
  background-color: #e0dee1 !important;
  color: #6e6b7b !important;
  border-color: #e0dee1 !important;
}
.modal1{
  padding: 0 !important;
}
</style>
